import { Formik, FormikHelpers, useField, useFormikContext } from "formik";
import OtpValidationForm from "Pages/Registration/OtpValidation/OtpValidationForm";
import { useCallback, useState } from "react";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { EditContentProps } from "./interfaces";
import * as Yup from "yup";
import { phoneValidation, requiredValidation } from "Utils/validations";
import { useSnackbar } from "notistack";
import {
  Stack,
  InputAdornment,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { OtpValidationData } from "Services/api/otp/interfaces";
import { updatePhone } from "Services/api/profile/profile";
import { validatePhone } from "Services/api/otp/otp";
import { getFullPhoneFormat } from "Utils/getFullPhoneFormat";
import { useHandleError } from "Hooks/useHandleError";
import ProfileToken, { checkToken } from "./ProfileToken";

const schema = Yup.object({
  tel: phoneValidation,
  channel: requiredValidation,
});

interface Payload {
  phone: string;
  code: string;
}

export function PhoneChange({ profile, setProfile, closeDialog }: EditContentProps) {
  const { phone } = profile;
  const [step, setStep] = useState<"edit" | "otp" | "verification">("edit");
  const { enqueueSnackbar } = useSnackbar();
  const [payload, setPayload] = useState<Payload>({ phone, code: "" });
  const handleError = useHandleError();

  const changePhone = useCallback(async (values: Payload) => {
    await checkToken(
      async (token) => {
        const phone = getFullPhoneFormat(values.phone);
        const { status, data } = await updatePhone({
          token,
          phone,
          code: values.code,
        });

        if (status === "success") {
          setProfile({ ...profile, phone });
          enqueueSnackbar(data, { variant: "success" });
          closeDialog();
        } else enqueueSnackbar(data, { variant: "error" });
      },
      () => {
        setPayload(values);
        setStep("verification");
      }
    );
  }, []);

  return step === "edit" ? (
    <Formik
      initialValues={{ tel: phone.replace("+1", ""), channel: "sms" }}
      validationSchema={schema}
      onSubmit={async ({ tel, channel }, { setSubmitting }) => {
        try {
          await validatePhone(getFullPhoneFormat(tel), channel);
          setPayload({ phone: tel, code: "" });
          setStep("otp");
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
          console.error(error);
        }
        setSubmitting(false);
      }}
    >
      <FormikForm width="100%">
        <Stack spacing={2}>
          <FormikTextField
            name="tel"
            id="tel"
            type="tel"
            fullWidth
            label="Celular:"
            variant="outlined"
            required
            InputProps={{
              startAdornment: <InputAdornment position="start">+1</InputAdornment>,
            }}
          />
          <FormikChannel name="channel" />

          <FormikSubmitButton fullWidth variant="contained">
            Continuar
          </FormikSubmitButton>
        </Stack>
      </FormikForm>
    </Formik>
  ) : step === "otp" ? (
    <OtpValidationForm
      channel="sms"
      channelDisplay="teléfono movil"
      to={payload.phone}
      expiryTime={600}
      onSubmit={onPhoneValidationSubmit}
    />
  ) : (
    <ProfileToken
      secretQuestions={profile.securityQuestions}
      onSubmit={async () => {
        await changePhone(payload);
      }}
    />
  );

  async function onPhoneValidationSubmit(
    { code }: { code: OtpValidationData["code"] },
    { setSubmitting }: FormikHelpers<{ code: string }>
  ) {
    try {
      await changePhone({ phone: payload.phone, code });
    } catch (error) {
      handleError(error);
    }

    setSubmitting(false);
  }
}

interface FormikChannelProps {
  name: string;
}

function FormikChannel(props: FormikChannelProps) {
  const [{ value }, , { setValue }] = useField<string>(props);
  const { isSubmitting, errors: formikErrors, touched } = useFormikContext<{ [k: string]: string }>();
  const formikError = Boolean(touched[props.name]) && Boolean(formikErrors[props.name]);

  const checkLabelStyle = {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "25px",
    letterSpacing: "0.02em",
    color: "#383838",
  };

  return (
    <FormControl component="fieldset" variant="standard" disabled={isSubmitting}>
      <FormLabel id={props.name}>Método de envio:</FormLabel>
      <RadioGroup
        aria-labelledby={props.name}
        value={value}
        name={props.name}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      >
        <FormControlLabel
          value="sms"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>SMS</Typography>}
        />

        <FormControlLabel
          value="whatsapp"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>WhatsApp</Typography>}
        />

        <FormControlLabel
          value="call"
          control={<Radio />}
          label={<Typography sx={{ checkLabelStyle }}>Llamada</Typography>}
        />
      </RadioGroup>
      {formikError ? <FormHelperText error> {formikErrors[props.name]}</FormHelperText> : null}
    </FormControl>
  );
}
