import { Formik, FormikHelpers } from "formik";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikForm from "Shared/FormikForm/FormikForm";
import * as Yup from "yup";
import OtpValidationForm from "../OtpValidation/OtpValidationForm";
import OtpValidation, { InputFormProps, ValidationFormProps } from "../OtpValidation/OtpValidation";
import { useSnackbar } from "notistack";
import { useRegistrationContext } from "../Registration";
import { ButtonBase, FormHelperText, InputAdornment, Link, Popover, Typography } from "@mui/material";
import { emailValidation } from "Utils/validations";
import { OtpValidationData } from "Services/api/otp/interfaces";
import { validateEmail } from "Services/api/otp/otp";
import { confirmEmail, saveEmail } from "Services/api/register/register";
import { useState } from "react";
import InfoIcon from "Icons/InfoIcon";

export default function EmailValidation(): JSX.Element {
  return (
    <OtpValidation
      title="Verificación de correo electrónico"
      InputForm={EmailInputForm}
      ValidationForm={EmailValidationForm}
    />
  );
}

const emailInputSchema = Yup.object({
  email: emailValidation,
});

function EmailInputForm({ setStatus, setTo }: InputFormProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [{ id, isAffiliatedClient, partialEmail }] = useRegistrationContext();
  const [emailError, setEmailError] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  return (
    <Formik initialValues={{ email: "" }} validationSchema={emailInputSchema} onSubmit={onEmailInputSubmit}>
      <FormikForm width="100%">
        {isAffiliatedClient ? (
          <Typography>
            Introduzca el email <strong>{partialEmail}</strong> para confirmar que todavía se le puede contactar por
            esta via. Si no reconoce o no recuerda este email puede{" "}
            <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/contactanos/">
              <strong>hacer click aqui para contactarnos.</strong>
            </Link>
          </Typography>
        ) : null}
        <FormikTextField
          name={"email"}
          id={"email"}
          type={"email"}
          fullWidth
          label="Correo electrónico:"
          variant="outlined"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment component={ButtonBase} position="end" onClick={handleClick}>
                <InfoIcon width="22" height="22" />
              </InputAdornment>
            ),
          }}
        />
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>
            ¿Por qué la verificación de correo?
            <br /> <br />
            Al proveer tu correo electrónico podrás:
            <ul>
              <li>Recibir tu estado de cuenta</li>
              <li>Recuperar tu contraseña</li>
              <li>Recibir notificaciones sobre nuevos productos</li>
              <li>Entre otros</li>
            </ul>
          </Typography>
        </Popover>
        {emailError ? <FormHelperText error>{emailError}</FormHelperText> : null}
        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );

  async function onEmailInputSubmit(
    { email }: { email: OtpValidationData["to"] },
    { setSubmitting }: FormikHelpers<{ email: OtpValidationData["to"] }>
  ) {
    setEmailError("");
    try {
      if (isAffiliatedClient) {
        const { status, data } = await confirmEmail(id, email);

        if (status === "success") {
          setStatus("validation");
          setTo(email);
          enqueueSnackbar(data, { variant: "success" });
        } else {
          setEmailError(data);
          enqueueSnackbar(data, { variant: "error" });
        }
      } else {
        await validateEmail(email);
        setStatus("validation");
        setTo(email);
      }
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }

    setSubmitting(false);
  }
}

function EmailValidationForm({ to }: ValidationFormProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [{ id }, setRegContext] = useRegistrationContext();

  return (
    <OtpValidationForm
      channel="email"
      channelDisplay="correo"
      to={to}
      expiryTime={600}
      onSubmit={onEmailValidationSubmit}
    />
  );

  async function onEmailValidationSubmit(
    { code }: { code: OtpValidationData["code"] },
    { setSubmitting }: FormikHelpers<{ code: string }>
  ) {
    try {
      const response = await saveEmail({
        id,
        to,
        code,
      });

      if (response.status === "success") {
        enqueueSnackbar("Correo validado", { variant: "success" });
        setRegContext({ status: response.data.status });
      } else enqueueSnackbar(response.data, { variant: "error" });
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }

    setSubmitting(false);
  }
}
