import { clientIdValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";

export const clientVerificationKeys: ["clientId", "incomeSource", "name", "rnc", "readRequirements"] = [
  "clientId",
  "incomeSource",
  "name",
  "rnc",
  "readRequirements",
];

type IncomeSourceOption = [string, string];

const salariedOptions: IncomeSourceOption[] = [
  ["publico", "Asalariado Público"],
  ["privado", "Asalariado Privado"],
  ["pensionado", "Pensionado"],
];

const nonSalariedOptions: IncomeSourceOption[] = [
  ["desempleado", "Desempleado"],
  ["independiente", "Independiente"],
  ["domestico", "Trabajador Doméstico"],
  ["otros", "Otros"],
];
export const incomeSourceOptions = [...salariedOptions, ...nonSalariedOptions];

export const salariedOptionsValues = salariedOptions.map((value) => value[0]);
const nonSalariedOptionsValues = nonSalariedOptions.map((value) => value[0]);
const incomeSourceOptionValues = [...salariedOptionsValues, ...nonSalariedOptionsValues];

export const clientVerificationSchema = Yup.object({
  [clientVerificationKeys[0]]: clientIdValidation,
  [clientVerificationKeys[1]]: requiredValidation.oneOf(incomeSourceOptionValues),
  [clientVerificationKeys[2]]: Yup.string().test({
    name: "required",
    message: "Requerido",
    test: (value, { parent: { incomeSource } }) => {
      const isSalaried = salariedOptionsValues.some((option) => option === incomeSource);

      return (isSalaried && Boolean(value)) || !isSalaried;
    },
  }),
  [clientVerificationKeys[3]]: Yup.string()
    .test({
      name: "required",
      message: "Requerido",
      test: (value, { parent: { incomeSource } }) => {
        const isSalaried = salariedOptionsValues.some((option) => option === incomeSource);

        return (isSalaried && Boolean(value)) || !isSalaried;
      },
    })
    .test({
      name: "rnc",
      message: "RNC invalido",
      test: (value, { parent: { incomeSource } }) => {
        const isSalaried = salariedOptionsValues.some((option) => option === incomeSource);
        const regex = /^[0-9]{1}-?[0-9]{2}-?[0-9]{5}-?[0-9]{1}$/g;

        return (isSalaried && Boolean(value?.match(regex))) || !isSalaried;
      },
    }),
  [clientVerificationKeys[4]]: Yup.boolean().isTrue("Debe leer y aceptar los requisitos de afiliación"),
});
