import { LoginData } from "Pages/Login/interfaces";
import SecretQuestionsForm from "Shared/SecretQuestionsForm/SecretQuestionsForm";
import { SecretQuestionData } from "Shared/SecretQuestionsForm/interfaces";
import { getToken } from "Services/api/auth/auth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";

export default function SecretQuestionsStep({ model }: { model: LoginData }): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return <SecretQuestionsForm secretQuestions={model.secretQuestions as SecretQuestionData[]} onSubmit={onSubmit} />;

  async function onSubmit(secretQuestions: SecretQuestionData[]) {
    try {
      const { status, data } = await getToken({
        ...model,
        secretQuestions,
      });

      if (status === "success") {
        localStorage.setItem("token", data.access);
        navigate("/");
      } else {
        enqueueSnackbar(data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
      console.error(error);
    }
  }
}
