import {
  ForgotPasswordDialogData,
  ForgotPasswordResponse,
  forgotPasswordResponseDecoder,
  ForgotPasswordData,
  RestorePasswordResponse,
  restorePasswordResponseDecoder,
} from "Pages/ForgotPassword/interfaces";
import {
  GetCurrentUserResponse,
  getCurrentUserResponseDecoder,
  GetTokenResponse,
  getTokenResponseDecoder,
  LoginData,
  LoginFormData,
  LoginResponse,
  loginResponseDecoder,
} from "Pages/Login/interfaces";
import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { FETCH_DEFAULT_CONFIGS, FETCH_DEFAULT_CONFIGS_TOKEN } from "../config";
import { SecretQuestionsResponse, secretQuestionsResponseDecoder } from "Shared/SecretQuestionsForm/interfaces";
import { LogoutResponse, logoutResponseDecoder } from "./interfaces";

export async function login(loginData: LoginFormData): Promise<LoginResponse> {
  const response = await enhancedFetch(`${API_URL}/auth/login/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(loginData),
  });

  return loginResponseDecoder.verify(await response.json());
}

export async function logout(): Promise<LogoutResponse> {
  const response = await enhancedFetch(`${API_URL}/auth/logout/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return logoutResponseDecoder.verify(await response.json());
}

export async function sendForgotPasswordEmail(model: ForgotPasswordDialogData): Promise<ForgotPasswordResponse> {
  const response = await enhancedFetch(`${API_URL}/auth/forgot-password/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(model),
  });

  return forgotPasswordResponseDecoder.verify(await response.json());
}

export async function getSecretQuestions(
  {
    uid,
    token,
  }: {
    uid: string;
    token: string;
  },
  query = ""
): Promise<SecretQuestionsResponse> {
  const response = await enhancedFetch(`${API_URL}/auth/secret-questions/${uid}/${token}/${query}`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return secretQuestionsResponseDecoder.verify(await response.json());
}

export async function restorePassword(model: ForgotPasswordData): Promise<RestorePasswordResponse> {
  const response = await enhancedFetch(`${API_URL}/auth/forgot-password/confirm/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({
      uid: model.uid,
      token: model.token,
      secretQuestions: model.secretQuestions,
      newPassword: model.newPassword,
      newPasswordConfirm: model.newPasswordConfirm,
    }),
  });

  return restorePasswordResponseDecoder.verify(await response.json());
}

export async function getToken(model: LoginData): Promise<GetTokenResponse> {
  const response = await enhancedFetch(`${API_URL}/token/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({
      uid: model.uid,
      token: model.token,
      secretQuestions: model.secretQuestions,
    }),
  });

  return getTokenResponseDecoder.verify(await response.json());
}

export async function getCurrentUser(): Promise<GetCurrentUserResponse> {
  const response = await enhancedFetch(`${API_URL}/users/me/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return getCurrentUserResponseDecoder.verify(await response.json());
}
