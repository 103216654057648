import { useQuery } from "@tanstack/react-query";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { RegistrationModel, SaveCertificatePayload } from "Services/api/register/interfaces";
import { saveCertificateData } from "Services/api/register/register";
import { CertificateLimitsResponse } from "Services/api/request/interfaces";
import { getCertificateLimits } from "Services/api/request/request";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import {
  CertificateForm,
  CertificateFormSkeleton,
  useCertificateFormSchema,
} from "Shared/CertificateForm/CertificateForm";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { Query } from "Shared/Query/Query";
import { requiredValidation } from "Utils/validations";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useRegistrationContext } from "../Registration";

export function Certificate() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getCertificateLimits.name],
    queryFn: async () => {
      try {
        const response = await getCertificateLimits();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <SplitFormContainer
      title="Solicitud de certificado"
      sideInfoTop={<AccessInfo />}
      form={
        <Query
          result={result}
          OnLoading={() => <CertificateFormSkeleton />}
          onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
          onSuccess={(result) => <Form result={result} />}
        />
      }
    />
  );
}

interface CertificateFormProps {
  result: CertificateLimitsResponse["data"];
}

function Form({ result }: CertificateFormProps) {
  const { minTimeLimit, maxTimeLimit } = result;
  const [{ id, institutionIsAffiliated, salary: dbSalary }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [minTerm] = [minTimeLimit, maxTimeLimit];

  const schema = useCertificateFormSchema({ options: result, institutionIsAffiliated, dbSalary });

  const initialValues: SaveCertificatePayload & { id: RegistrationModel["id"] } = {
    id,
    amount: null,
    salary: null,
    sourceOfFundsDesc: "",
    sourceOfFundsFile: undefined,
    term: minTerm,
    liquidation: "",
    typeofPayment: "",
    savingsAccount: "",
    account: "",
    accountNumber: "",
    accountBank: "",
    accountType: "",
    accountId: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const requestData = new FormData();

          for (const key in values) {
            const el = values[key as keyof SaveCertificatePayload];
            if (el) requestData.append(key, typeof el === "number" ? el.toString() : el);
          }
          const { data } = await saveCertificateData(requestData);
          setRegContext(data);
          setSubmitting(false);
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        }
      }}
      validationSchema={Yup.object({ id: requiredValidation }).concat(schema)}
    >
      <FormikForm width="100%">
        <CertificateForm
          isRegistration
          options={result}
          institutionIsAffiliated={institutionIsAffiliated}
          dbSalary={dbSalary}
        />
        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );
}
