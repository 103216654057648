import { TextFieldProps } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { LabeledTextField } from "Shared/LabeledTextField/LabeledTextField";

export default function FormikTextField(
  props: TextFieldProps & { name: string; step?: string; min?: string }
): JSX.Element {
  const { isSubmitting, errors, touched } = useFormikContext<{ [k: string]: string }>();
  const error = Boolean(touched[props.name]) && Boolean(errors[props.name]);

  return (
    <Field
      as={LabeledTextField}
      disabled={isSubmitting}
      error={error}
      helperText={error ? errors[props.name] : ""}
      {...props}
    />
  );
}
