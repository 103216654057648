import { ChevronRight, Search } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Transfer } from "Services/api/request/interfaces";
import { getTransfers } from "Services/api/request/request";
import { MoneyFormat } from "Shared/MoneyFormat/MoneyFormat";
import { Query } from "Shared/Query/Query";
import ValueElement from "Shared/ValueElement/ValueElement";

export function Transfers() {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const result = useQuery({
    queryKey: [getTransfers.name],
    queryFn: async () => {
      try {
        const { data } = await getTransfers();
        return data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Stack spacing={2}>
      <Button
        variant="outlined"
        endIcon={<ChevronRight />}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          py: "16px",
          width: { sm: "fit-content" },
        }}
        component={Link}
        to="solicitud"
      >
        Realizar transferencia
      </Button>
      <Stack spacing={2} component={Card} style={{ backgroundColor: "#F6F9F2" }} elevation={0}>
        <AppBar position="static" sx={{ boxShadow: "none" }}>
          <Toolbar>
            <Stack width="100%" alignItems={"center"} gap="10px 5px" sx={{ flexDirection: { md: "row" } }}>
              <Stack flexGrow={1} spacing={1}>
                <Typography component="h2">
                  <strong>Transferencias</strong>
                </Typography>
              </Stack>
              <Stack
                flexGrow={0}
                gap="20px 5px"
                alignItems={"center"}
                sx={{ flexDirection: { md: "row" }, py: "15px" }}
              >
                <Stack direction={"row"} alignItems={"center"} gap="5px">
                  <Typography>Desde:</Typography>
                  <TextField
                    name="from"
                    id="from"
                    fullWidth
                    variant="standard"
                    type="date"
                    placeholder="desde:"
                    inputProps={{ sx: { backgroundColor: "#fff", padding: "5px", borderRadius: "3px" } }}
                  />
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap="5px">
                  <Typography>Hasta:</Typography>
                  <TextField
                    name="to"
                    id="to"
                    fullWidth
                    variant="standard"
                    type="date"
                    inputProps={{ sx: { backgroundColor: "#fff", padding: "5px", borderRadius: "3px" } }}
                  />
                </Stack>
                <IconButton
                  data-testid="search"
                  className="logout-button"
                  sx={{ backgroundColor: "#fff", width: "fit-content" }}
                >
                  <Search htmlColor={theme.palette.primary.main} />
                </IconButton>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
        <Query
          result={result}
          OnLoading={() => (
            <Stack spacing={2} alignItems="center">
              <CircularProgress color="inherit" />
              <div>Cargando transferencias...</div>
            </Stack>
          )}
          onSuccess={(values) =>
            values.length > 0 ? <TransfersTable values={values} /> : <div>No se encontraron transacciones.</div>
          }
        />
      </Stack>
    </Stack>
  );
}

interface Page<T> {
  count: number;
  rowsPerPage: number;
  current: number;
  values: T[];
}

type TransferPage = Page<Transfer>;

interface TransfersTableProps {
  values: Transfer[];
}

function TransfersTable({ values }: TransfersTableProps) {
  const [page, setPage] = useState<TransferPage>({
    count: values.length,
    rowsPerPage: 5,
    current: 0,
    values: values.slice(0, 5),
  });

  return (
    <Box style={{ border: "1px solid rgba(224,224,224,1)", margin: 0 }}>
      <Table style={{ marginTop: 0 }} aria-label="tabla de transferencias" sx={{ display: { md: "none" } }}>
        <TableBody>
          {page.values.map(({ id, date, from, to, amount, status }) => {
            return (
              <StyledTableRow key={id}>
                <TableCell>
                  <Stack sx={{ p: "10px" }} spacing={1}>
                    <ValueElement
                      title={"Número:"}
                      value={id}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"Fecha:"}
                      value={date}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"De:"}
                      value={from}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"A:"}
                      value={to}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"Monto:"}
                      value={<MoneyFormat amount={amount} />}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"Estado:"}
                      value={status}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                  </Stack>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
      <Table
        style={{ marginTop: 0 }}
        aria-label="tabla de transferencias"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: { display: "none" },
        })}
      >
        <TableHead>
          <TableRow sx={{ backgroundColor: "#fff" }}>
            <TableCell>Número</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Origen</TableCell>
            <TableCell>Destino</TableCell>
            <TableCell>Monto</TableCell>
            <TableCell>Estado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {page.values.map(({ id, date, from, to, amount, status }) => {
            return (
              <StyledTableRow key={id}>
                <TableCell>{id}</TableCell>
                <TableCell sx={{ textWrap: "nowrap" }}>{date}</TableCell>
                <TableCell>{from}</TableCell>
                <TableCell>{to}</TableCell>
                <TableCell>
                  <MoneyFormat amount={amount} />
                </TableCell>

                <TableCell>{status}</TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component={Stack}
        count={page.count}
        rowsPerPage={page.rowsPerPage}
        page={page.current}
        onPageChange={(_: unknown, newPage: number) => {
          const initTrans = newPage * page.rowsPerPage;
          setPage({
            ...page,
            current: newPage,
            values: values.slice(initTrans, initTrans + page.rowsPerPage),
          });
        }}
        onRowsPerPageChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          const rowsPerPage = Number(value);
          setPage({
            ...page,
            current: 0,
            rowsPerPage: rowsPerPage,
            values: values.slice(0, rowsPerPage),
          });
        }}
        sx={{
          backgroundColor: page.values.length % 2 !== 0 ? "#fff" : "",
        }}
      />
    </Box>
  );
}

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(even)": {
    backgroundColor: "#fff",
  },
});
