import { Button, Stack } from "@mui/material";
import BiometricStep1 from "Icons/BiometricStep1";
import FrontId from "Icons/FrontId";
import BackId from "Icons/BackId";
import BiometricStep2 from "Icons/BiometricStep2";
import FacePhoto from "Icons/FacePhoto";
import { FormProps } from "./interfaces";
import { Text, StepText } from "./styled";

export default function Instructions(props: FormProps) {
  const { onSubmit } = props;
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Text>A continuación verificaremos tu identidad y para ser verficado, es necesario:</Text>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <BiometricStep1 style={{ minWidth: "50px" }} />
          <StepText>Subir o tomar foto delantera y trasera de tu cédula</StepText>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent={"center"}>
          <FrontId style={{ width: "50%" }} />
          <BackId style={{ width: "50%" }} />
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          <BiometricStep2 style={{ minWidth: "50px" }} />
          <StepText>Tomar fotos o video de tu rostro</StepText>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent={"center"}>
          <FacePhoto style={{ width: "50%" }} />
        </Stack>
      </Stack>
      <Button fullWidth variant="contained" onClick={() => void (onSubmit as () => Promise<void>)()}>
        Iniciar
      </Button>
    </Stack>
  );
}
