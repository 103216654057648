import { Skeleton, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { SaveSavingsRequestPayload } from "Services/api/register/interfaces";
import { saveSavingsData } from "Services/api/register/register";
import { SavingsDepositLimitsResponse, SavingsPlanPoolResponse } from "Services/api/request/interfaces";
import { getSavingsAccountDepositLimits, getSavingsPlanPool } from "Services/api/request/request";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { Query } from "Shared/Query/Query";
import {
  SavingsAccountForm,
  SavingsAccountType,
  useSavingsAccountFormSchema,
} from "Shared/SavingsAccountForm/SavingsAccountForm";
import { SavingsPlanForm, useSavingsPlanFormSchema } from "Shared/SavingsPlanForm/SavingsPlanForm";
import { SavingsTypeSelect } from "Shared/SavingsTypeSelect/SavingsTypeSelect";
import { requiredValidation } from "Utils/validations";
import { Formik, useField } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useRegistrationContext } from "../Registration";

type SavingsRequestType = SavingsAccountType | "plan";

export function Savings() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getSavingsPlanPool.name],
    queryFn: async () => {
      try {
        const response = await getSavingsPlanPool();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const limitsResult = useQuery({
    queryKey: [getSavingsAccountDepositLimits.name],
    queryFn: async () => {
      try {
        const response = await getSavingsAccountDepositLimits();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <SplitFormContainer
      title="Solicitud de ahorros"
      sideInfoTop={<AccessInfo />}
      form={
        <Query
          result={limitsResult}
          OnLoading={() => (
            <Stack width={"100%"} spacing={2}>
              <Stack spacing={1} width="100%">
                <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
                <Skeleton variant="rectangular" width={"100%"} height={56} />
              </Stack>
              <Skeleton variant="rectangular" width={"100%"} height={37} />
            </Stack>
          )}
          onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
          onSuccess={(limitsResult) => (
            <Query
              result={result}
              OnLoading={() => (
                <Stack width={"100%"} spacing={2}>
                  <Stack spacing={1} width="100%">
                    <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
                    <Skeleton variant="rectangular" width={"100%"} height={56} />
                  </Stack>
                  <Skeleton variant="rectangular" width={"100%"} height={37} />
                </Stack>
              )}
              onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
              onSuccess={(result) => <SavingsForm result={result} limitsResult={limitsResult} />}
            />
          )}
        />
      }
    />
  );
}

interface SavingsFormProps {
  result: SavingsPlanPoolResponse["data"];
  limitsResult: SavingsDepositLimitsResponse["data"];
}

function SavingsForm({ result, limitsResult }: SavingsFormProps) {
  const { minMonthlyDeposit } = limitsResult;
  const { planDepositMin, planGoalMin, planTermMin } = result;

  const [{ id }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useCreateValidationSchema(result, limitsResult);
  const initialValues: SaveSavingsRequestPayload = {
    id,
    type: "",
    childName: "",
    childAge: "",
    birthCertificate: undefined,
    initialDeposit: null,
    salary: null,
    sourceOfFundsDesc: "",
    sourceOfFundsFile: undefined,
    monthlyDeposit: minMonthlyDeposit,
    typeOfPlan: "",
    planDeposit: planDepositMin,
    planGoal: planGoalMin,
    planTerm: planTermMin,
    periodicity: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const requestData = new FormData();

          for (const key in values) {
            const el = values[key as keyof SaveSavingsRequestPayload];
            if (el) requestData.append(key, typeof el === "number" ? el.toString() : el);
          }
          const { data } = await saveSavingsData(requestData);
          setRegContext(data);
          setSubmitting(false);
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        }
      }}
      validationSchema={validationSchema}
    >
      <FormikForm width="100%">
        <SavingsTypeSelect name="type" label="Tipo de solicitud" />
        <SavingsAccount result={limitsResult} />
        <SavingsPlan result={result} />

        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );
}

function useCreateValidationSchema(
  result: SavingsFormProps["result"],
  { minMonthlyDeposit, maxMonthlyDeposit }: SavingsFormProps["limitsResult"]
) {
  const [{ institutionIsAffiliated, salary: dbSalary }] = useRegistrationContext();
  const savingsAccountFormSchema = useSavingsAccountFormSchema({
    minMonthlyDeposit,
    maxMonthlyDeposit,
    institutionIsAffiliated,
    dbSalary,
  });
  const savingsPlanFormSchema = useSavingsPlanFormSchema(result);

  return Yup.object({
    id: requiredValidation,
  })
    .concat(savingsAccountFormSchema)
    .concat(savingsPlanFormSchema);
}

function SavingsAccount({
  result: { minMonthlyDeposit, maxMonthlyDeposit },
}: {
  result: SavingsFormProps["limitsResult"];
}) {
  const [{ institutionIsAffiliated, salary: dbSalary }] = useRegistrationContext();
  const [{ value }] = useField<SavingsRequestType>("type");

  if (value !== "in_sight" && value !== "youth") return null;

  return (
    <SavingsAccountForm
      minMonthlyDeposit={minMonthlyDeposit}
      maxMonthlyDeposit={maxMonthlyDeposit}
      type={value}
      institutionIsAffiliated={institutionIsAffiliated}
      dbSalary={dbSalary}
    />
  );
}

function SavingsPlan({ result }: { result: SavingsFormProps["result"] }) {
  const [{ value: type }] = useField<SavingsRequestType>("type");

  if (type !== "plan") return null;

  return <SavingsPlanForm options={result} />;
}
