import { MenuItem, Skeleton, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useField } from "formik";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { getBankAccounts } from "Services/api/profile/profile";
import { getAccountTypeChoices, getBankChoices } from "Services/api/request/request";
import ClientIdInput from "Shared/ClientIdInput/ClientIdInput";
import FormikSelect from "Shared/FormikSelect/FormikSelect";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { Query } from "Shared/Query/Query";
import { clientIdValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";

export function BankAccountFields({
  accountField,
  label,
  showAccountField = true,
}: {
  accountField: string;
  label: string;
  showAccountField?: boolean;
}) {
  return (
    <Fragment>
      {showAccountField && <BankAccountSelect accountField={accountField} label={label} />}
      <NewAccountFields accountField={accountField} />
    </Fragment>
  );
}

function BankAccountSelect({ accountField, label }: { accountField: string; label: string }) {
  const { enqueueSnackbar } = useSnackbar();
  const result = useQuery({
    queryKey: [getBankAccounts.name],
    queryFn: async () => {
      try {
        const response = await getBankAccounts();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Error obteniendo cuentas de banco del cliente", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Query
      result={result}
      OnLoading={() => (
        <Stack spacing={1} width="100%">
          <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
          <Skeleton variant="rectangular" width={"100%"} height={56} />
        </Stack>
      )}
      onError={() => (
        <FormikSelect
          id={accountField}
          name={accountField}
          label={label}
          SelectDisplayProps={{ "aria-label": label }}
          required
        >
          <MenuItem value="new">Agregar cuenta</MenuItem>
        </FormikSelect>
      )}
      onSuccess={(bankAccounts) => (
        <FormikSelect
          id={accountField}
          name={accountField}
          label={label}
          SelectDisplayProps={{ "aria-label": label }}
          required
        >
          <MenuItem value="new">Agregar cuenta</MenuItem>
          {bankAccounts.map(({ id, number, bank }) => {
            return (
              <MenuItem key={id} value={id}>
                - {number} - {bank}
              </MenuItem>
            );
          })}
        </FormikSelect>
      )}
    />
  );
}

function NewAccountFields({ accountField }: { accountField: string }) {
  const [{ value: account }] = useField<string>(accountField);

  if (account !== "new") return null;

  return (
    <Fragment>
      <FormikTextField id="accountNumber" name="accountNumber" label="Numero de cuenta" variant="outlined" required />
      <BankAccountField />
      <AccountTypeField />
      <ClientIdInput name="accountId" id="accountId" label="Cédula de beneficiario" />
    </Fragment>
  );
}

function BankAccountField() {
  const { enqueueSnackbar } = useSnackbar();
  const getBanks = useQuery({
    queryKey: [getBankChoices.name],
    queryFn: async () => {
      try {
        const response = await getBankChoices();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Error obteniendo instituciones financieras", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Query
      result={getBanks}
      OnLoading={() => (
        <Stack spacing={1} width="100%">
          <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
          <Skeleton variant="rectangular" width={"100%"} height={56} />
        </Stack>
      )}
      onError={() => (
        <FormikSelect
          id="accountBank"
          name="accountBank"
          label="Institución financiera"
          SelectDisplayProps={{ "aria-label": "Institución financiera" }}
          required
        ></FormikSelect>
      )}
      onSuccess={(banks) => (
        <FormikSelect
          id="accountBank"
          name="accountBank"
          label="Institución financiera"
          SelectDisplayProps={{ "aria-label": "Institución financiera" }}
          required
        >
          {banks.map(([value, display]) => (
            <MenuItem key={value} value={value}>
              {display}
            </MenuItem>
          ))}
        </FormikSelect>
      )}
    />
  );
}

function AccountTypeField() {
  const { enqueueSnackbar } = useSnackbar();

  const getAccountTypes = useQuery({
    queryKey: [getAccountTypeChoices.name],
    queryFn: async () => {
      try {
        const response = await getAccountTypeChoices();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Error obteniendo los tipos de cuentas", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Query
      result={getAccountTypes}
      OnLoading={() => (
        <Stack spacing={1} width="100%">
          <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
          <Skeleton variant="rectangular" width={"100%"} height={56} />
        </Stack>
      )}
      onError={() => (
        <FormikSelect
          id="accountType"
          name="accountType"
          label="Tipo de cuenta"
          SelectDisplayProps={{ "aria-label": "Tipo de cuenta" }}
          required
        ></FormikSelect>
      )}
      onSuccess={(accountTypes) => (
        <FormikSelect
          id="accountType"
          name="accountType"
          label="Tipo de cuenta"
          SelectDisplayProps={{ "aria-label": "Tipo de cuenta" }}
          required
        >
          {accountTypes.map(([value, display]) => (
            <MenuItem key={value} value={value}>
              {display}
            </MenuItem>
          ))}
        </FormikSelect>
      )}
    />
  );
}

export function BankAccountFieldsSchema(accountField: string) {
  return Yup.object({
    accountNumber: Yup.string().when(accountField, {
      is: "new",
      then: () => requiredValidation,
    }),
    accountBank: Yup.string().when(accountField, {
      is: "new",
      then: () => requiredValidation,
    }),
    accountType: Yup.string().when(accountField, {
      is: "new",
      then: () => requiredValidation,
    }),
    accountId: Yup.string().when(accountField, {
      is: "new",
      then: () => clientIdValidation,
    }),
  });
}
