import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RegistrationModel } from "Services/api/register/interfaces";
import { getRegistration } from "Services/api/register/register";
import { Backdrop, BackdropState } from "Shared/Backdrop/Backdrop";
import { AffiliationPurpose } from "./AffiliationPurpose/AffiliationPurpose";
import { AffiliationTerms } from "./AffiliationTerms/AffiliationTerms";
import CellphoneValidation from "./CellphoneValidation/CellphoneValidation";
import { Certificate } from "./Certificate/Certificate";
import ClientForm from "./ClientForm/ClientForm";
import ClientVerification from "./ClientVerification/ClientVerification";
import Complete from "./Complete/Complete";
import EmailValidation from "./EmailValidation/EmailValidation";
import IdentityVerification from "./IdentityVerification/IdentityVerification";
import { Loan } from "./Loan/Loan";
import { Savings } from "./Savings/Savings";
import { Terms } from "./Terms/Terms";
import UserForm from "./UserForm/UserForm";

const defaultRegContext: RegistrationModel = {
  id: "",
  status: "client-verification",
  isAffiliatedClient: false,
  partialEmail: "",
  partialNumber: "",
  institutionIsAffiliated: false,
  salary: null,
  isResumable: false,
};

type SetRegContext = (overrides: Partial<RegistrationModel>) => void;

const RegistrationContext = createContext<[RegistrationModel, SetRegContext]>([
  defaultRegContext,
  (() => {}) as SetRegContext,
]);

export function useRegistrationContext() {
  return useContext(RegistrationContext);
}

const RegistrationView: { [k: string]: (props?: unknown) => JSX.Element } = {
  "client-verification": ClientVerification,
  terms: Terms,
  "affiliation-purpose": AffiliationPurpose,
  "email-validation": EmailValidation,
  "phone-validation": CellphoneValidation,
  loan: Loan,
  savings: Savings,
  certificate: Certificate,
  "affiliation-terms": AffiliationTerms,
  "identity-verification": IdentityVerification,
  "client-configuration": ClientForm,
  "user-configuration": UserForm,
  complete: Complete,
};

export default function Registration(): JSX.Element {
  return <RegistrationContextProvider />;
}

export function RegistrationContextProvider({
  children,
  context = defaultRegContext,
  useSearchP = useSearchParams,
  renderRegistrationElement = true,
}: PropsWithChildren<{
  context?: RegistrationModel;
  useSearchP?: typeof useSearchParams;
  renderRegistrationElement?: boolean;
}>) {
  const [regContext, setRegContext] = useState<RegistrationModel>(context);

  const RegistrationElement = RegistrationView[regContext.status];
  const [searchParams] = useSearchP();
  const shouldResume = searchParams.has("id");
  const [backdropState, setBackdropState] = useState<BackdropState>({
    open: shouldResume,
    msg: "Cargando datos ...",
  });

  useEffect(() => {
    if (shouldResume) {
      const payload = {
        id: searchParams.get("id") || "",
      };
      void onLoad({ ...payload });
    }
  }, [shouldResume, searchParams]);

  return (
    <RegistrationContext.Provider
      value={[regContext, (overrides: Partial<RegistrationModel>) => setRegContext({ ...regContext, ...overrides })]}
    >
      {renderRegistrationElement ? <RegistrationElement /> : children}
      <Backdrop {...backdropState} />
    </RegistrationContext.Provider>
  );

  /* istanbul ignore next */
  async function onLoad({ id }: { id: string }) {
    try {
      const { status, data } = await getRegistration(id);
      if (status === "success" && data.isResumable) {
        setRegContext(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBackdropState({
        open: false,
      });
    }
  }
}
