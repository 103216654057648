import {
  AppBar,
  Box,
  Card,
  Grid,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAsync } from "Hooks/useAsync";
import { useSnackbar } from "notistack";
import { Dispatch, Fragment, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { getLoanDetails } from "Services/api/finance/finance";
import { LoanDetails } from "Services/api/finance/interfaces";
import { Loading } from "Shared/Loading/Loading";
import { MoneyFormat } from "Shared/MoneyFormat/MoneyFormat";
import ValueElement from "Shared/ValueElement/ValueElement";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(even)": {
    backgroundColor: "#fff",
  },
});

interface Page {
  count: number;
  rowsPerPage: number;
  current: number;
  transactions: LoanDetails["transactions"];
}

export function LoanDetails(): JSX.Element {
  const { number } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [details, setDetails] = useState<LoanDetails["details"] | undefined>();
  const [transactions, setTransactions] = useState<LoanDetails["transactions"]>([]);
  const [page, setPage] = useState<Page>({
    count: 0,
    rowsPerPage: 0,
    current: 0,
    transactions: [],
  });

  const getInitData = useCallback(async () => {
    if (number) {
      const { status, data } = await getLoanDetails(number);
      if (status === "success") {
        const { details, transactions } = data;
        setDetails(details);
        transactions.reverse();
        setTransactions(transactions);
        setPage({
          count: transactions.length,
          rowsPerPage: 5,
          current: 0,
          transactions: transactions.slice(0, 5),
        });
      }
    }
  }, [number]);
  const { status } = useAsync(getInitData, null, (error) => {
    enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
    console.error(error);
  });

  return (
    <Stack width="100%" spacing={1}>
      {status === "pending" || status === "idle" ? (
        <Loading />
      ) : status === "success" && details ? (
        <Fragment>
          <AppBar position="static" sx={{ boxShadow: "none", borderRadius: "4px" }}>
            <Toolbar>
              <Typography component="h2">
                <strong>Detalles</strong>
              </Typography>
            </Toolbar>
          </AppBar>
          <Stack
            spacing={2}
            component={Card}
            style={{ padding: "30px", backgroundColor: "#F6F9F2" }}
            elevation={0}
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Typography sx={{ color: "#315500", fontWeight: "bold" }}>
              Saldo Pendiente Prestamo: {details.loanNumber}
            </Typography>
            <Typography sx={{ color: "#315500", fontSize: "2rem", fontWeight: "bold" }}>
              <MoneyFormat amount={details.loanOutstandingBalance} />
            </Typography>
          </Stack>

          <Stack spacing={1} component={Card} style={{ backgroundColor: "#F6F9F2" }} elevation={0}>
            <AppBar position="static" sx={{ boxShadow: "none" }}>
              <Toolbar>
                <Typography component="h2">
                  <strong>Detalles del producto</strong>
                </Typography>
              </Toolbar>
            </AppBar>

            <Grid container spacing={2} padding="0 15px 15px 15px">
              <Grid
                item
                sm={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <ValueElement title={"Número de prestamo"} value={details.loanNumber} />
                <ValueElement title={"Estado"} value={details.loanStatus} />
                <ValueElement title={"Fecha de desembolso"} value={details.disbursementDate} />
                <ValueElement title={"Frecuencia de pagos"} value={details.paymentFrequency} />
              </Grid>
              <Grid
                item
                sm={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <ValueElement title={"Monto original"} value={<MoneyFormat amount={details.loanAmount} />} />
                <ValueElement title={"Dia de pago"} value={details.paymentDay} />
                <ValueElement title={"Tasa vigente (anual)"} value={details.interestRate} />
                <ValueElement title={"Cantidad de cuotas pagadas"} value={details.remainingPeriods} />
              </Grid>
              <Grid
                item
                sm={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <ValueElement
                  title={"Saldo pendiente"}
                  value={<MoneyFormat amount={details.loanOutstandingBalance} />}
                />
                <ValueElement
                  title={"Fecha de último pago"}
                  value={details.lastPaymentDate ? details.lastPaymentDate : "No se ha realizado el primer pago"}
                />
                <ValueElement title={"Próximo pago"} value={details.nextPaymentDue} />
                <ValueElement title={"Cuota"} value={<MoneyFormat amount={details.nextAmountDue} />} />
              </Grid>
            </Grid>
          </Stack>

          <Transactions {...{ transactions, page, setPage }} />
        </Fragment>
      ) : status === "error" ? (
        <div>{GENERIC_ERROR_MESSAGE}</div>
      ) : (
        <></>
      )}
    </Stack>
  );
}

interface TransactionsProps {
  transactions: LoanDetails["transactions"];
  page: Page;
  setPage: Dispatch<Page>;
}

function Transactions(props: TransactionsProps) {
  const { transactions, page, setPage } = props;

  return (
    <Stack spacing={2} component={Card} style={{ backgroundColor: "#F6F9F2" }} elevation={0}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <Stack width="100%" alignItems={"center"} gap="10px 5px" sx={{ flexDirection: { md: "row" } }}>
            <Stack flexGrow={1} spacing={1}>
              <Typography component="h2">
                <strong>Movimientos</strong>
              </Typography>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <TransactionsTable {...{ transactions, page, setPage }} />
    </Stack>
  );
}

interface TransactionsTableProps {
  transactions: LoanDetails["transactions"];
  page: Page;
  setPage: Dispatch<Page>;
}

function TransactionsTable(props: TransactionsTableProps) {
  const { transactions, page, setPage } = props;
  return (
    <Box style={{ border: "1px solid rgba(224,224,224,1)", margin: 0 }}>
      <Table style={{ marginTop: 0 }} aria-label="tabla de transferencias" sx={{ display: { md: "none" } }}>
        <TableBody>
          {page.transactions.map((item) => {
            return (
              <StyledTableRow key={item.transactionReference}>
                <TableCell>
                  <Stack sx={{ p: "10px" }} spacing={1}>
                    <ValueElement
                      title={"Número:"}
                      value={item.transactionReference}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"Fecha:"}
                      value={item.paymentDate}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"Capital:"}
                      value={<MoneyFormat amount={item.principalPaid} />}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"Interes:"}
                      value={<MoneyFormat amount={item.interestPaid} />}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"Seguro:"}
                      value={<MoneyFormat amount={item.insurancePaid} />}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"Mora:"}
                      value={<MoneyFormat amount={item.arrearsPaid} />}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"Otros:"}
                      value={<MoneyFormat amount={item.otherChargesPaid} />}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"Total:"}
                      value={<MoneyFormat amount={item.totalAmountPaid} />}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                    <ValueElement
                      title={"Pendiente:"}
                      value={<MoneyFormat amount={item.endingBalance} />}
                      stackProps={{
                        direction: "row",
                        spacing: 1,
                      }}
                    />
                  </Stack>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
      <Table
        style={{ marginTop: 0 }}
        aria-label="tabla de movimientos"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: { display: "none" },
        })}
      >
        <TableHead>
          <TableRow sx={{ backgroundColor: "#fff" }}>
            <TableCell>Número</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell align="right">Capital</TableCell>
            <TableCell align="right">Interes</TableCell>
            <TableCell align="right">Seguro</TableCell>
            <TableCell align="right">Mora</TableCell>
            <TableCell align="right">Otros</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Pendiente</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {page.transactions.map((item) => {
            return (
              <StyledTableRow key={item.transactionReference}>
                <TableCell>{item.transactionReference}</TableCell>
                <TableCell>{item.paymentDate}</TableCell>
                <TableCell align="right">
                  <MoneyFormat amount={item.principalPaid} />
                </TableCell>
                <TableCell align="right">
                  <MoneyFormat amount={item.interestPaid} />
                </TableCell>
                <TableCell align="right">
                  <MoneyFormat amount={item.insurancePaid} />
                </TableCell>
                <TableCell align="right">
                  <MoneyFormat amount={item.arrearsPaid} />
                </TableCell>
                <TableCell align="right">
                  <MoneyFormat amount={item.otherChargesPaid} />
                </TableCell>
                <TableCell align="right">
                  <MoneyFormat amount={item.totalAmountPaid} />
                </TableCell>
                <TableCell align="right">
                  <MoneyFormat amount={item.endingBalance} />
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={page.count}
        rowsPerPage={page.rowsPerPage}
        page={page.current}
        onPageChange={(_: unknown, newPage: number) => {
          const initTrans = newPage * page.rowsPerPage;
          setPage({
            ...page,
            current: newPage,
            transactions: transactions.slice(initTrans, initTrans + page.rowsPerPage),
          });
        }}
        onRowsPerPageChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          const rowsPerPage = Number(value);
          setPage({
            ...page,
            current: 0,
            rowsPerPage: rowsPerPage,
            transactions: transactions.slice(0, rowsPerPage),
          });
        }}
        sx={{
          backgroundColor: page.transactions.length % 2 !== 0 ? "#fff" : "",
        }}
      />
    </Box>
  );
}
