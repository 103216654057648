import * as Yup from "yup";

export const IdValidation = Yup.string()
  .matches(/^[0-9]+(-[0-9]*)*$/, "Identificación invalida")
  .required("Requerido");

export const clientIdValidation = Yup.string()
  .matches(/^[0-9]{3}-?[0-9]{7}-?[0-9]{1}$/, "Cédula invalida")
  .required("Requerido");

export const requiredValidation = Yup.string().required("Requerido");

export const requiredNumberValidation = Yup.number().required("Requerido");

export const passwordValidation = Yup.string().required("Contraseña requerida").length(4, "Contraseña incompleta");

export const emailValidation = requiredValidation.email("Correo incorrecto");

export const phoneValidation = Yup.number()
  .required("Requerido")
  .integer("Solo se permiten numeros")
  .typeError("Solo se permiten numeros");
