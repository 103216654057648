import { Formik, FormikHelpers } from "formik";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { otpCodeSchema, OtpProps, OtpValidationFormFields } from "Shared/OtpValidation/OtpValidation";
import * as Yup from "yup";

const otpValidationSchema = Yup.object({
  code: otpCodeSchema,
});

interface Props extends OtpProps {
  onSubmit: (values: { code: string }, { setSubmitting }: FormikHelpers<{ code: string }>) => Promise<void>;
}

export default function OtpValidationForm(props: Props): JSX.Element {
  const { onSubmit } = props;

  return (
    <Formik initialValues={{ code: "" }} validationSchema={otpValidationSchema} onSubmit={onSubmit}>
      <FormikForm width="100%">
        <OtpValidationFormFields {...props} />
        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );
}
