export default function ArrowBackIcon(): JSX.Element {
  return (
    <svg width="20" height="17" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.008 10.3415L1.80375 10.3381M10.2041 18.7415L1.80375 10.3381L10.2071 1.93774"
        stroke="#315500"
        strokeWidth="2.40053"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
