import { FormControl, FormHelperText, MenuItem, Select, SelectProps, Skeleton, Stack, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";

export interface FormikSelectProps<T = unknown> extends SelectProps<T> {
  name: string;
  status?: "pending" | "error";
  helperText?: string;
}

export default function FormikSelect<T>(props: FormikSelectProps<T>): JSX.Element {
  const { helperText, ...filteredProps } = props;
  const { isSubmitting, errors, touched } = useFormikContext<{ [k: string]: string }>();
  const error = Boolean(touched[props.name]) && Boolean(errors[props.name]);
  const ht = helperText ? helperText : "";

  return (
    <Field
      as={LabeledSelect}
      disabled={isSubmitting}
      error={error}
      helperText={error ? errors[props.name] : ht}
      {...filteredProps}
    />
  );
}

function LabeledSelect(
  props: SelectProps & {
    name: string;
    label: React.ReactNode;
    error: boolean;
    helperText: React.ReactNode;
    status?: "pending" | "error";
  }
): JSX.Element {
  const { error, helperText, label, placeholder, children, status, SelectDisplayProps, ...filteredProps } = props;
  return (
    <Stack spacing={1} component={FormControl} fullWidth justifyContent="center">
      {label && <label htmlFor={filteredProps.id}>{label}</label>}

      {status === "pending" ? (
        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mt: "8px", borderRadius: "5px" }} />
      ) : status === "error" ? (
        <Typography color="#d32f2f">Error cargando opciones</Typography>
      ) : (
        // @ts-expect-error test id
        <Select {...filteredProps} SelectDisplayProps={{ "data-testid": filteredProps.id, ...SelectDisplayProps }}>
          {placeholder ? (
            <MenuItem disabled value="">
              <span style={{ color: "#BABABA" }}>{placeholder}</span>
            </MenuItem>
          ) : null}
          {children}
        </Select>
      )}

      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Stack>
  );
}
