import { Stack } from "@mui/material";
import SideInfo from "../SideInfo/SideInfo";

export const description = "Para poder comenzar el proceso de afiliación debes cumplir con los siguientes requisitos";

export const steps = [
  "Nacionalidad dominicana.",
  "No ser una persona políticamente expuesta (PEP).",
  "Tener un correo electrónico válido.",
  "Acceso a un dispositivo con cámara.",
  "Poseer contigo tu documento de identificación o cedula.",
];

export default function AccessInfo(): JSX.Element {
  return (
    <Stack spacing={2} direction="column" sx={{ position: "sticky", top: "35vh" }}>
      <SideInfo title={"Proceso de afiliación digital"} description={description} steps={steps} />
    </Stack>
  );
}
