import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { OtpValidationData } from "Services/api/otp/interfaces";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import BackButton from "Shared/BackButton/BackButton";
import { Dispatch, useState } from "react";

type Status = "input" | "validation";

export interface InputFormProps {
  setStatus: Dispatch<Status>;
  setTo: Dispatch<string>;
}

export interface ValidationFormProps {
  to: OtpValidationData["to"];
}

interface Props {
  title: string;
  InputForm: ({ setStatus, setTo }: InputFormProps) => JSX.Element;
  ValidationForm: ({ to }: ValidationFormProps) => JSX.Element;
}

export default function OtpValidation({ title, InputForm, ValidationForm }: Props): JSX.Element {
  const [status, setStatus] = useState<Status>("input");
  const [to, setTo] = useState<OtpValidationData["to"]>("");

  const { title: formTitle, Back } =
    status === "input"
      ? {
          title,
          Back: (() => <></>) as typeof BackButton,
        }
      : {
          title: "Código de seguridad",
          Back: BackButton,
        };

  return (
    <SplitFormContainer
      title={formTitle}
      before={<Back onClick={() => setStatus("input")} />}
      sideInfoTop={<AccessInfo />}
      form={status === "input" ? <InputForm setStatus={setStatus} setTo={setTo} /> : <ValidationForm to={to} />}
    />
  );
}
