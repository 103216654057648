export default async function enhancedFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  const response = await fetch(input, init);
  if (!response.ok) {
    const responseError = {
      statusText: response.statusText,
      status: response.status,
      data: await parseResponseData(response),
    };
    throw responseError;
  }
  return response;
}

async function parseResponseData(response: Response): Promise<unknown> {
  const contentType = response.headers.get("Content-Type");
  /* istanbul ignore if */
  if (contentType === "application/json") return (await response.json()) as unknown;
  /* istanbul ignore if */
  if (contentType === "text/plain") return await response.text();
  return "";
}
