import { Close } from "@mui/icons-material";
import {
  AppBar,
  Button,
  CircularProgress,
  debounce,
  Dialog,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useField, useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from "react";
import { useNumericFormat } from "react-number-format";
import { SavingsPlan, SavingsPlanPayload, SavingsPlanPoolResponse } from "Services/api/request/interfaces";
import { calculatePlan, getSavingsPlanChoices, getSavingsPlanPeriodicityChoices } from "Services/api/request/request";
import { FormikMoney, numericConfig } from "Shared/FormikMoney/FormikMoney";
import FormikSelect, { FormikSelectProps } from "Shared/FormikSelect/FormikSelect";
import { FormikSlider } from "Shared/FormikSlider/FormikSlider";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { Query } from "Shared/Query/Query";
import * as Yup from "yup";
import { debounceTime } from "./debounceTime";

type Periodicity = "monthly" | "biweekly";
type TypeOfPlan = SavingsPlanPayload["typeOfPlan"];

export function SavingsPlanForm({
  options: { planGoalMin, planTermMin, planDepositMin, planTermMax, planInterest },
}: {
  options: SavingsPlanPoolResponse["data"];
}) {
  const [{ value: typeOfPlan }] = useField<TypeOfPlan>("typeOfPlan");
  const [{ value: periodicity }] = useField<Periodicity>("periodicity");
  const [, , { setValue: setDeposit }] = useField<number>("planDeposit");
  const [, , { setValue: setGoal }] = useField<number>("planGoal");
  const [, , { setValue: setTerm }] = useField<number>("planTerm");
  const isBiweekly = periodicity === "biweekly";
  const biWeeklyGoalMultiplier = isBiweekly ? 2 : 1;
  const enableMonthlyDeposit = Boolean(typeOfPlan) && typeOfPlan !== "quota";
  const enableGoal = Boolean(typeOfPlan) && typeOfPlan !== "amount";
  const enableTerm = Boolean(typeOfPlan) && typeOfPlan !== "periods";

  useEffect(() => {
    if (typeOfPlan === "quota") {
      setGoal(planGoalMin);
      setTerm(planTermMin);
    }
    if (typeOfPlan === "amount") {
      setDeposit(planDepositMin / biWeeklyGoalMultiplier);
      setTerm(planTermMin);
    }
    if (typeOfPlan === "periods") {
      setGoal(planGoalMin);
      setDeposit(planDepositMin / biWeeklyGoalMultiplier);
    }
  }, [typeOfPlan, periodicity]);

  return (
    <Fragment>
      <TypeOfPlanSelect />
      <PeriodicitySelect />
      <FormikMoney
        id="planDeposit"
        name="planDeposit"
        label={`Cuota ${isBiweekly ? "quincenal" : "mensual"}`}
        variant="outlined"
        required={enableMonthlyDeposit}
        disabled={!enableMonthlyDeposit}
      />
      <FormikMoney
        id="planGoal"
        name="planGoal"
        label="Meta"
        variant="outlined"
        required={enableGoal}
        disabled={!enableGoal}
      />
      <FormikSlider
        id="planTerm"
        name="planTerm"
        label="Plazo"
        aria-label="Plazo"
        valueLabelDisplay="off"
        min={planTermMin}
        max={planTermMax}
        step={6}
        marks
        formatValue={(value) => `${value} meses`}
        hideSlider={!enableTerm}
        disabled={!enableTerm}
      />

      <FormikTextField
        id="interest"
        name="interest"
        label="Interes anual"
        type="number"
        value={planInterest.toString()}
        variant="outlined"
        disabled
      />

      <SavingsPlan />
    </Fragment>
  );
}

function TypeOfPlanSelect() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getSavingsPlanChoices.name],
    queryFn: async () => {
      try {
        const response = await getSavingsPlanChoices();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const props: FormikSelectProps<string> = {
    id: "typeOfPlan",
    name: "typeOfPlan",
    label: "Tipo de plan",
    SelectDisplayProps: { "aria-label": "Tipo de plan" },
    required: true,
  };

  return (
    <Query
      result={result}
      OnLoading={() => (
        <Stack spacing={1} width="100%">
          <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
          <Skeleton variant="rectangular" width={"100%"} height={56} />
        </Stack>
      )}
      onError={() => <FormikSelect {...props} />}
      onSuccess={(typeOfPlanOptions) => (
        <FormikSelect {...props}>
          {typeOfPlanOptions.map(([value, display]) => (
            <MenuItem key={value} value={value}>
              {display}
            </MenuItem>
          ))}
        </FormikSelect>
      )}
    />
  );
}

function PeriodicitySelect() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getSavingsPlanPeriodicityChoices.name],
    queryFn: async () => {
      try {
        const response = await getSavingsPlanPeriodicityChoices();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const props: FormikSelectProps<string> = {
    id: "periodicity",
    name: "periodicity",
    label: "Periodicidad",
    SelectDisplayProps: { "aria-label": "Periodicidad" },
    required: true,
  };

  return (
    <Query
      result={result}
      OnLoading={() => (
        <Stack spacing={1} width="100%">
          <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
          <Skeleton variant="rectangular" width={"100%"} height={56} />
        </Stack>
      )}
      onError={() => <FormikSelect {...props} />}
      onSuccess={(periodicityOptions) => (
        <FormikSelect {...props}>
          {periodicityOptions.map(([value, display]) => (
            <MenuItem key={value} value={value}>
              {display}
            </MenuItem>
          ))}
        </FormikSelect>
      )}
    />
  );
}

function SavingsPlan() {
  const { values, setFieldValue } = useFormikContext<SavingsPlanPayload>();
  const [table, setTable] = useState<SavingsPlan["table"]>([]);
  const [openSavingsPlan, setOpenSavingsPlan] = useState(false);
  const [cacheValues, setCacheValues] = useState<SavingsPlanPayload>(values);
  const [calculating, setCalculating] = useState(false);

  const { mutateAsync: calculate } = useMutation({
    mutationFn: async () => {
      const { typeOfPlan, periodicity, planDeposit, planGoal, planTerm } = values;
      if (typeOfPlan && periodicity && planDeposit && planGoal && planTerm) {
        let refetch = false;
        if (typeOfPlan != cacheValues.typeOfPlan) refetch = true;
        else if (periodicity != cacheValues.periodicity) refetch = true;
        else if (typeOfPlan === "quota" && (planGoal !== cacheValues.planGoal || planTerm !== cacheValues.planTerm))
          refetch = true;
        else if (
          typeOfPlan === "amount" &&
          (planDeposit !== cacheValues.planDeposit || planTerm !== cacheValues.planTerm)
        )
          refetch = true;
        else if (
          typeOfPlan === "periods" &&
          (planGoal !== cacheValues.planGoal || planDeposit !== cacheValues.planDeposit)
        )
          refetch = true;

        if (refetch) {
          setCalculating(true);
          const {
            data: { table },
          } = await calculatePlan(values);
          setTable(table);
          if (typeOfPlan === "quota" && table[1]) {
            const deposit = Number(table[1][3].replace(",", ""));
            setFieldValue("planDeposit", deposit);
            setCacheValues({ ...values, planDeposit: deposit });
          } else if (typeOfPlan === "amount" && table[table.length - 1]) {
            const goal = Number(table[table.length - 1][6].replace(",", ""));
            setFieldValue("planGoal", goal);
            setCacheValues({ ...values, planDeposit: goal });
          } else if (typeOfPlan === "periods") {
            const term = table.length - 1;
            setFieldValue("planTerm", term);
            setCacheValues({ ...values, planTerm: term });
          }
        }
      } else setTable([]);
      setCalculating(false);
    },
    onError: () => {
      setCalculating(false);
    },
  });

  const fetch = useMemo(
    () =>
      debounce(() => {
        void calculate();
      }, debounceTime),
    [calculate]
  );

  useEffect(() => {
    fetch();
  }, [values, fetch]);

  return (
    <Fragment>
      {!calculating && table.length > 0 && (
        <Button variant="outlined" onClick={() => setOpenSavingsPlan(true)}>
          Ver plan de ahorro
        </Button>
      )}
      {calculating && (
        <Stack spacing={2} alignItems="center">
          <CircularProgress color="inherit" />
          <div>Calculando plan...</div>
        </Stack>
      )}
      <SavingsPlanDialog open={openSavingsPlan} setOpen={setOpenSavingsPlan} table={table} />
    </Fragment>
  );
}

interface SavingsPlanDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  table: SavingsPlan["table"];
}

function SavingsPlanDialog({ open, setOpen, table }: SavingsPlanDialogProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Plan de ahorro
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            CERRAR
          </Button>
        </Toolbar>
      </AppBar>
      <Table width="100%" sx={{ mt: { xs: "64px", sm: "72px" } }}>
        <TableHead>
          <TableRow>
            <TableCell>Periodo</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell sx={{ textWrap: "nowrap" }}>Balance Inicial</TableCell>
            <TableCell>Cuota</TableCell>
            <TableCell>Interés</TableCell>
            <TableCell>Retención</TableCell>
            <TableCell sx={{ textWrap: "nowrap" }}>Balance Final</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {table.map((row) => {
            const [period, date, initial, quota, interest, retention, final] = row;

            if (period === 0) return null;

            return (
              <TableRow key={period}>
                <TableCell>{period}</TableCell>
                <TableCell>{date}</TableCell>
                <TableCell>{initial}</TableCell>
                <TableCell>{quota}</TableCell>
                <TableCell>{interest}</TableCell>
                <TableCell>{retention}</TableCell>
                <TableCell>{final}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Dialog>
  );
}

export function useSavingsPlanFormSchema({
  planDepositMin,
  planGoalMin,
  planTermMin,
  planTermMax,
}: SavingsPlanPoolResponse["data"]) {
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig });

  return Yup.object({
    typeOfPlan: Yup.string().when("type", {
      is: "plan",
      then: (schema) => schema.required("Requerido"),
    }),
    planDeposit: Yup.number()
      .nullable()
      .when("type", {
        is: "plan",
        then: (schema) =>
          schema.when("typeOfPlan", {
            is: (value: TypeOfPlan) => value !== "quota",
            then: (schema) =>
              schema.required("Requerido").min(planDepositMin, `No menos de RD$${format(planDepositMin.toString())}`),
          }),
      })
      .when(["type"], {
        is: "plan",
        then: (schema) =>
          schema.when(["typeOfPlan", "periodicity"], {
            is: (typeOfPlan: TypeOfPlan, periodicity: Periodicity) =>
              typeOfPlan !== "quota" && periodicity === "biweekly",
            then: (schema) =>
              schema.min(planDepositMin / 2, `No menos de RD$${format((planDepositMin / 2).toString())}`),
          }),
      })
      .test(
        "depositAndGoal",
        "Cuota debe ser menor que meta",
        (planDeposit, { parent: { planGoal } }) => (planDeposit || 0) < (planGoal || 0)
      ),
    planGoal: Yup.number()
      .nullable()
      .when("type", {
        is: "plan",
        then: (schema) =>
          schema.when("typeOfPlan", {
            is: (typeOfPlan: TypeOfPlan) => typeOfPlan !== "amount",
            then: (schema) =>
              schema.required("Requerido").min(planGoalMin, `No menos de RD$${format(planGoalMin.toString())}`),
          }),
      }),
    planTerm: Yup.number()
      .integer("Debe ser un entero")
      .positive("Debe ser un entero positivo")
      .min(planTermMin, `No menos de ${planTermMin} meses`)
      .max(planTermMax, `No mas de ${planTermMax} meses`),
    periodicity: Yup.string().when("type", {
      is: "plan",
      then: (schema) => schema.required("Requerido"),
    }),
  });
}
