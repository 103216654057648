import { jsendResponseDecoder, jsendSuccessDecoder } from "Utils/jsend";
import { array, DecoderType, string, tuple } from "decoders";

import { object } from "decoders";

export const errorKeyResponseDecoder = object({
  errorKey: string,
  message: string,
});

export type ErrorKeyResponse = DecoderType<typeof errorKeyResponseDecoder>;

export const errorKeyJsendResponseDecoder = jsendResponseDecoder(string, errorKeyResponseDecoder);

export type ErrorKeyJsendResponse = DecoderType<typeof errorKeyJsendResponseDecoder>;

export const stringJsendResponseDecoder = jsendResponseDecoder(string, string);

export type StringJsendResponse = DecoderType<typeof stringJsendResponseDecoder>;

export const stringJsendSuccessResponseDecoder = jsendSuccessDecoder(string);

export type StringJsendSuccessResponse = DecoderType<typeof stringJsendSuccessResponseDecoder>;

export const choicesDecoder = array(tuple(string, string));

export type Choices = DecoderType<typeof choicesDecoder>;

export const choicesJsendSuccessResponseDecoder = jsendSuccessDecoder(choicesDecoder);

export type ChoicesJsendSuccessResponse = DecoderType<typeof choicesJsendSuccessResponseDecoder>;
