import { Button, Grid, MenuItem } from "@mui/material";
import { ClientFormData } from "Services/api/register/interfaces";
import { FormikMoney } from "Shared/FormikMoney/FormikMoney";
import FormikSelect from "Shared/FormikSelect/FormikSelect";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { Fragment } from "react";
import { StepFormProps, clientFormSchema } from "./interfaces";

export function WorkForm<T extends FormikValues>(props: StepFormProps<T>) {
  const {
    options: { paymentTypes, sessionTypes, positions, departments, economicSectors },
    onSubmit,
    initialValues,
    onBack,
  } = props;
  const { salaryWasCaptured, dateOfHireWasCaptured, shouldCaptureInstitution } =
    initialValues as ClientFormData["work"];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: T, { setSubmitting }: FormikHelpers<T>) => {
        onSubmit(values, "work");
        setSubmitting(false);
      }}
      validationSchema={clientFormSchema.work}
    >
      <Grid component={Form} container width="100%" spacing={2}>
        <Grid item sm={6} width="100%">
          <FormikMoney
            name="salary"
            id="salary"
            fullWidth
            variant="outlined"
            label={"Salario mensual:"}
            disabled={salaryWasCaptured}
          />
        </Grid>

        <Grid item sm={6} width="100%">
          <FormikTextField
            name="dateOfHire"
            id="dateOfHire"
            fullWidth
            label="Fecha de entrada:"
            variant="outlined"
            type="date"
            required
            disabled={dateOfHireWasCaptured}
          />
        </Grid>

        <Grid item sm={6} width="100%">
          <FormikSelect
            id="sessionType"
            name="sessionType"
            label="Tipo de jornada:"
            SelectDisplayProps={{ "aria-label": "Tipo de jornada:" }}
            required
          >
            {sessionTypes.map(([value, display]) => (
              <MenuItem key={value} value={value}>
                {display}
              </MenuItem>
            ))}
          </FormikSelect>
        </Grid>

        <Grid item sm={6} width="100%">
          <FormikSelect
            id="paymentType"
            name="paymentType"
            label="Forma de pago:"
            SelectDisplayProps={{ "aria-label": "Forma de pago:" }}
            required
          >
            {paymentTypes.map(([value, display]) => (
              <MenuItem key={value} value={value}>
                {display}
              </MenuItem>
            ))}
          </FormikSelect>
        </Grid>

        <Grid item sm={6} width="100%">
          <FormikSelect
            name="position"
            id="position"
            label="Cargo/posición:"
            SelectDisplayProps={{ "aria-label": "Cargo/posición:" }}
            required
          >
            {positions.map((positionOption) => (
              <MenuItem key={positionOption.unique_id} value={positionOption.name}>
                {positionOption.name}
              </MenuItem>
            ))}
          </FormikSelect>
        </Grid>
        <Grid item sm={6} width="100%">
          <FormikSelect
            name="department"
            id="department"
            label="Departamento:"
            SelectDisplayProps={{ "aria-label": "Departamento:" }}
            required
          >
            {departments.map((departmentOption) => (
              <MenuItem key={departmentOption.unique_id} value={departmentOption.name}>
                {departmentOption.name}
              </MenuItem>
            ))}
          </FormikSelect>
        </Grid>

        {shouldCaptureInstitution && (
          <Fragment>
            <Grid item sm={6} width="100%">
              <FormikTextField
                name="institutionEmail"
                id="institutionEmail"
                label="Correo de la empresa:"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item sm={6} width="100%">
              <FormikTextField
                name="institutionPhone"
                id="institutionPhone"
                label="Telefono de la empresa:"
                variant="outlined"
                required
              />
            </Grid>

            <Grid item sm={6} width="100%">
              <FormikSelect
                name="economicSector"
                id="economicSector"
                label="Sector Economico:"
                SelectDisplayProps={{ "aria-label": "Sector Economico:" }}
                required
              >
                {economicSectors.map((economicSectorOption) => (
                  <MenuItem key={economicSectorOption.unique_id} value={economicSectorOption.name}>
                    {economicSectorOption.name}
                  </MenuItem>
                ))}
              </FormikSelect>
            </Grid>
          </Fragment>
        )}

        <Grid item sm={12} width="100%"></Grid>

        <Grid item xs={6} width="100%">
          <Button fullWidth variant="outlined" onClick={onBack}>
            Atrás
          </Button>
        </Grid>
        <Grid item xs={6} width="100%">
          <FormikSubmitButton fullWidth variant="contained">
            Siguiente
          </FormikSubmitButton>
        </Grid>
      </Grid>
    </Formik>
  );
}
