import { Link, Typography } from "@mui/material";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { acceptTerms } from "Services/api/register/register";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import FormikCheckbox from "Shared/FormikCheckbox/FormikCheckbox";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useRegistrationContext } from "../Registration";

export function Terms() {
  const [{ id }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <SplitFormContainer
      title="Términos y condiciones"
      sideInfoTop={<AccessInfo />}
      form={
        <Formik
          initialValues={{ terms: false }}
          onSubmit={async ({ terms }, { setSubmitting }) => {
            try {
              const response = await acceptTerms(id, terms);
              setRegContext({ status: response.data.status });
              setSubmitting(false);
            } catch (error) {
              enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
            }
          }}
          validationSchema={Yup.object({
            terms: Yup.boolean().isTrue("Debe leer y aceptar los terminos y condiciones"),
          })}
        >
          <FormikForm width="100%">
            <FormikCheckbox
              name="terms"
              label={
                <Typography>
                  Yo acepto y autorizo el registro de mi información para el propósito descrito en los{" "}
                  <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/condiciones-uso-digital/">
                    Terminos y condiciones
                  </Link>
                </Typography>
              }
            />
            <FormikSubmitButton fullWidth variant="contained">
              Siguiente
            </FormikSubmitButton>
          </FormikForm>
        </Formik>
      }
    />
  );
}
