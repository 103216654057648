import { Button } from "@mui/material";
import ArrowBackIcon from "Icons/ArrowBackIcon";

export default function BackButton({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <Button
      sx={{ fontWeight: 700, fontSize: "20px", lineHeight: "29px", mt: "15px" }}
      startIcon={<ArrowBackIcon />}
      onClick={onClick}
    >
      Atrás
    </Button>
  );
}
