import { Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { AffiliationOptionsResponse } from "Services/api/register/interfaces";
import { getAffiliationTermsOptions, saveAffiliationTerms } from "Services/api/register/register";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import FormikCheckbox from "Shared/FormikCheckbox/FormikCheckbox";
import FormikForm from "Shared/FormikForm/FormikForm";
import { FormikMoney, FormikMoneyField, numericConfig } from "Shared/FormikMoney/FormikMoney";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { Query } from "Shared/Query/Query";
import { TypeOfPayment } from "Shared/TypeOfPayment/TypeOfPayment";
import { requiredNumberValidation, requiredValidation } from "Utils/validations";
import { Formik, useField } from "formik";
import { useSnackbar } from "notistack";
import { useNumericFormat } from "react-number-format";
import * as Yup from "yup";
import { useRegistrationContext } from "../Registration";

export function AffiliationTerms() {
  const [{ id }] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getAffiliationTermsOptions.name],
    queryFn: async () => {
      try {
        const response = await getAffiliationTermsOptions({ id });
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <SplitFormContainer
      title="Términos de Afiliación"
      sideInfoTop={<AccessInfo />}
      form={
        <Query
          result={result}
          OnLoading={() => (
            <Stack width={"100%"} spacing={2}>
              <Stack spacing={1} width="100%">
                <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
                <Skeleton variant="rectangular" width={"100%"} height={56} />
              </Stack>
              <Stack spacing={1} width="100%">
                <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
                <Skeleton variant="rectangular" width={"100%"} height={56} />
              </Stack>
              <Skeleton variant="rectangular" width={"100%"} height={37} />
            </Stack>
          )}
          onSuccess={(result) => <Form result={result} />}
        />
      }
    />
  );
}

function Form({ result }: { result: AffiliationOptionsResponse["data"] }) {
  const {
    loanIncludesFees,
    distribution: {
      total: [, , minQuota],
    },
  } = result;

  const [{ id, institutionIsAffiliated }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);

  return (
    <Formik
      initialValues={{ id, monthlyDiscount: null, discountFromSavings: false, typeofPayment: "" }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const { data } = await saveAffiliationTerms(values);
          setRegContext(data);
          setSubmitting(false);
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        }
      }}
      validationSchema={Yup.object({
        id: requiredValidation,
        monthlyDiscount: Yup.number()
          .nullable()
          .when("discountFromSavings", {
            is: true,
            then: () => requiredNumberValidation.min(minQuota, `Monto minimo: RD$${format(minQuota.toString())}`),
          }),
        typeofPayment: Yup.string(),
        discountFromSavings: Yup.boolean(),
      })}
    >
      <FormikForm width="100%">
        <MonthlyDiscount result={result} />
        <FormikCheckbox name="discountFromSavings" label="Descontar obligaciones de ahorro del monto mensual" />
        {!institutionIsAffiliated && !loanIncludesFees && <TypeOfPayment />}
        <DistributionTable result={result} />
        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );
}

function MonthlyDiscount({
  result: {
    distribution: {
      total: [, , minQuota],
    },
  },
}: {
  result: AffiliationOptionsResponse["data"];
}) {
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);
  const [{ value: discountFromSavings }, ,] = useField<boolean>("discountFromSavings");
  return (
    <FormikMoney
      id="monthlyDiscount"
      name="monthlyDiscount"
      label="Descuento mensual"
      helperText={discountFromSavings && minQuota > 0 ? `Monto minimo: RD$${format(minQuota.toString())}` : null}
    />
  );
}

function DistributionTable({
  result: {
    distribution: { values, total },
    loanIncludesFees,
  },
}: {
  result: AffiliationOptionsResponse["data"];
}) {
  let [{ value: monthlyDiscount }, ,] = useField<FormikMoneyField>("monthlyDiscount");
  const [{ value: discountFromSavings }, ,] = useField<boolean>("discountFromSavings");
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);
  monthlyDiscount = monthlyDiscount || 0;
  let initialExtraordinary = 0;
  let quotaExtraordinary = 0;

  if (discountFromSavings) {
    if (!loanIncludesFees) {
      initialExtraordinary = monthlyDiscount - total[1];
      if (initialExtraordinary < 0) initialExtraordinary = 0;
    }
    quotaExtraordinary = monthlyDiscount - total[2];
    if (quotaExtraordinary < 0) quotaExtraordinary = 0;
  } else {
    if (!loanIncludesFees) initialExtraordinary = monthlyDiscount;
    quotaExtraordinary = monthlyDiscount;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>CUADRO DE DISTRIBUCIÓN</TableCell>
          <TableCell align="center">Aporte inicial</TableCell>
          <TableCell align="center">Cuotas mensuales</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {values.map(([id, heading, initial, quota]) => (
          <TableRow key={id}>
            <TableCell>{heading}</TableCell>
            <TableCell align="center">
              {id === "savings"
                ? `RD$${format((initial + initialExtraordinary).toString())}`
                : `RD$${format(initial.toString())}`}
            </TableCell>
            <TableCell align="center">
              {id === "savings"
                ? `RD$${format((quota + quotaExtraordinary).toString())}`
                : `RD$${format(quota.toString())}`}
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <strong>{total[0]}</strong>
          </TableCell>
          <TableCell align="center">
            <strong>RD${format((total[1] + initialExtraordinary).toString())}</strong>
          </TableCell>
          <TableCell align="center">
            <strong>RD${format((total[2] + quotaExtraordinary).toString())}</strong>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
