import {
  ContactSupport,
  Description,
  Home,
  Logout,
  ManageAccounts,
  Payment,
  Payments,
  People,
} from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useSingleEffect } from "Hooks/useSingleEffect";
import NavBar from "Layout/NavBar/NavBar";
import { UserData } from "Pages/Login/interfaces";
import { getCurrentUser, logout } from "Services/api/auth/auth";
import { Backdrop, BackdropState } from "Shared/Backdrop/Backdrop";
import { useSnackbar } from "notistack";
import { Fragment, PropsWithChildren, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import logo from "../../../../public/logo.png";

export default function Dashboard({ children }: PropsWithChildren): JSX.Element {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserData | null>(null);
  const [backdropState, setBackdropState] = useState<BackdropState>({
    open: true,
    msg: "Cargando ...",
  });
  const [mobileOpen, setMobileOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /* istanbul ignore next */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  async function onLogout() {
    try {
      await logout();
      localStorage.removeItem("token");
      navigate("../acceso");
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error al intentar cerrar sesión", {
        variant: "error",
      });
      console.error(error);
    }
  }

  useSingleEffect(() => void onLoad());

  return (
    <Fragment>
      {user && (
        <Fragment>
          <Box component="nav">
            <Drawer
              open={mobileOpen}
              onClose={handleDrawerToggle}
              sx={(theme) => ({
                [theme.breakpoints.up("sm")]: { display: "none" },
                "& .MuiDrawer-paper": {
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "#fff",
                  boxSizing: "border-box",
                },
              })}
            >
              <DrawerContent onLogout={onLogout} handleDrawerToggle={handleDrawerToggle} />
            </Drawer>
            <Drawer
              variant="persistent"
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: { display: "none" },
              })}
              elevation={0}
              open
              PaperProps={{
                sx: {
                  zIndex: 0,
                },
              }}
            >
              <DrawerContent onLogout={onLogout} />
            </Drawer>
          </Box>
          <NavBar
            loggedIn
            rightMenu={<RightMenu name={`${user.first_name} ${user.last_name}`} onLogout={onLogout} />}
            handleDrawerToggle={handleDrawerToggle}
          >
            <Box
              sx={{
                ml: {
                  sm: "250px",
                },
                mt: "80px",
                px: 3,
                mb: {
                  xs: "80px",
                  sm: 3,
                },
              }}
            >
              {children}
            </Box>
          </NavBar>
          <Paper
            sx={(theme) => ({
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              [theme.breakpoints.up("sm")]: {
                display: "none",
              },
            })}
            elevation={3}
          >
            <BottomNavigation showLabels sx={{ backgroundColor: "#547725" }}>
              <BottomNavigationAction
                label="Inicio"
                icon={<Home />}
                sx={{ color: "#fff" }}
                component={RouterLink}
                to={"/productos"}
              />
              <BottomNavigationAction
                label="Solicitudes"
                icon={<Description />}
                sx={{ color: "#fff" }}
                component={RouterLink}
                to={"/solicitudes"}
              />
              <BottomNavigationAction
                label="Perfil"
                icon={<ManageAccounts />}
                sx={{ color: "#fff" }}
                component={RouterLink}
                to={"/socio"}
              />
            </BottomNavigation>
          </Paper>
        </Fragment>
      )}

      <Backdrop {...backdropState} />
    </Fragment>
  );

  async function onLoad() {
    const goToLogin = () => navigate("/acceso");
    const token = localStorage.getItem("token");
    if (!token) {
      goToLogin();
      return;
    }

    try {
      const { status, data } = await getCurrentUser();
      if (status === "success") {
        setUser(data);
      } else {
        goToLogin();
      }
    } catch (error) {
      goToLogin();
      console.error(error);
    } finally {
      setBackdropState({
        open: false,
      });
    }
  }
}

interface RightMenuProps {
  name: string;
  onLogout: () => Promise<void>;
}

function RightMenu({ name, onLogout }: RightMenuProps) {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <Typography
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        })}
      >
        Bienvenido/a {name}
      </Typography>

      <IconButton className="logout-button" sx={{ backgroundColor: "#fff" }} component={RouterLink} to="/contacto">
        <ContactSupport htmlColor={theme.palette.primary.main} />
      </IconButton>

      <IconButton
        className="logout-button"
        sx={{ backgroundColor: "#fff" }}
        onClick={() => void onLogout()}
        aria-label="cerrar sesión"
        data-testid="logout"
      >
        <Logout htmlColor={theme.palette.primary.main} />
      </IconButton>
    </Stack>
  );
}

interface DrawerContentProps {
  handleDrawerToggle?: () => void;
  onLogout: () => Promise<void>;
}

function DrawerContent({ handleDrawerToggle = () => undefined, onLogout }: DrawerContentProps) {
  return (
    <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerToggle}>
      <Toolbar sx={{ px: [2] }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Box src={logo} sx={{ height: 40 }} component="img" alt="Cooperativa Barcelona logo" />
        </Stack>
      </Toolbar>
      <List>
        <NavItem route="/productos" text="Inicio" icon={Home} />
        <NavItem route="/solicitudes" text="Solicitudes" icon={Description} />
        <NavItem route="/transferencias" text="Transferencias" icon={Payments} />
        <NavItem route="" text="Pagos de servicios" icon={Payment} disabled />
        <NavItem route="" text="Beneficiarios" icon={People} disabled />
        <NavItem route="/socio" text="Perfil" icon={ManageAccounts} />
        <NavItem route="/contacto" text="Contacto" icon={ContactSupport} />

        <ListItem disablePadding>
          <ListItemButton onClick={() => void onLogout()} aria-label="cerrar sesión">
            <>
              <Logout sx={{ marginRight: "6px" }} />
              <ListItemText primary="Salir" />
            </>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}

function NavItem({
  route,
  text,
  icon: Icon,
  disabled = false,
}: {
  route: string;
  text: string;
  icon?: React.ElementType | undefined;
  disabled?: boolean;
}) {
  return (
    <ListItem disablePadding>
      <ListItemButton component={RouterLink} to={route} disabled={disabled}>
        <>
          {Icon && <Icon sx={{ marginRight: "6px" }} />}
          <ListItemText primary={text} />
        </>
      </ListItemButton>
    </ListItem>
  );
}
